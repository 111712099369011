import React from 'react';
import { NavLink } from "react-router-dom";
import "../Nav.css";

function Nav() {
    const links = [["Home", "/"], ["Pong", "/pong"], ["Pi Game", "/pi"], ["Clicker", "/clicker"]];

    return (
        <ul id="list" role="menu">
             {links.map((item, index) => <li key={index}><NavLink role="tab" to={item[1]}>{item[0]}</NavLink></li>)}
        </ul>
    );
}

export default Nav;