import React from 'react';

function Home() {
    return (
        <div style={{"padding":"10px"}}>
            <h1>ScienceKat's homepage!</h1>
            <p>hoi</p>
        </div>
    );
}

export default Home;
