/* global BigInt */
import React, {useEffect, useRef, useState} from "react";
import styles from "../PiGame.module.css";

function PiGame() {
    const loseMsgs = ["You should have put a %!",
        "You lost! Next time, put a % there.",
        "Liaf uoy (correct was %)",
        "You put a %... not",
        "Keep going! The next number is %.",
        "I have a feeling % is the correct digit.",
        "Hello? %?"
    ];
    const documentRef = useRef(document);
    let currentMsg = useRef(["", ""]);
    let piStr = useRef("314");
    let digits = useRef(3);
    let [currentNum, setCurrentNum] = useState("3.1");
    let lastNum = useRef("4");
    let [cursor, setCursor] = useState("_");
    let cursorInterval: React.MutableRefObject<NodeJS.Timer | undefined> = useRef();
    let eventListenerAdded = useRef(false);
    let [lost, setLost] = useState(false);
    let tries = useRef(2);

    const typeNum = (event: KeyboardEvent) => {
        let num = Number(event.key);
        if (num || num === 0) {
            let x = lastNum.current;
            lastNum.current = num.toString();
            setCurrentNum(prev => prev + x);
            if (Number(piStr.current.charAt(digits.current)) === num) {
                digits.current++;
            } else {
                currentMsg.current = loseMsgs[Math.floor(Math.random() * loseMsgs.length)].split("%");
                setLost(true);
                documentRef.current.removeEventListener('keydown', typeNum);
            }
        }
    }

    if(!eventListenerAdded.current) {
        eventListenerAdded.current = true;
        documentRef.current.addEventListener('keydown', typeNum);
    }

    const Restart = () => {
        return (
            <>
                <span> | {currentMsg.current[0]}<b>{piStr.current.charAt(digits.current)}</b>{currentMsg.current[1]} </span>
                <span className={styles.link} onClick={restart}>(Restart)</span>
                <span> </span>
                <span className={styles.link} onClick={continueTry}>{tries.current > 0 ? "(Continue with " + tries.current + " tries left)" : ""}</span>
            </>
        )
    }

    const restart = () => {
        setLost(false);
        setCurrentNum("3.1");
        lastNum.current = "4";
        digits.current = 3;
        tries.current = 2;
        documentRef.current.addEventListener('keydown', typeNum);
    }

    const continueTry = () => {
        setLost(false);
        tries.current --;
        lastNum.current = currentNum.charAt(currentNum.length - 1);
        setCurrentNum(prev => prev.substring(0, prev.length - 1));
        documentRef.current.addEventListener('keydown', typeNum);
    }

    const flashCursor = () => {
        setCursor(prev => prev ? "" : "_");
    }

    useEffect(() => {
        let i = BigInt(1);
        let x: bigint = BigInt(3) * (BigInt(10) ** BigInt(1000));
        let pi: bigint = x;
        while (x > 0) {
            x = x * i / ((i + BigInt(1)) * BigInt(4));
            pi += x / (i + BigInt(2));
            i += BigInt(2);
        }
        piStr.current = (pi / (BigInt(10) ** BigInt(20))).toString();
        console.log(piStr.current);
        if(!cursorInterval.current) {
            cursorInterval.current = setInterval(flashCursor, 500);
        }
    }, []);

    return (
        <div style={{'paddingLeft': '10px'}}>
            <div className={styles.header}>
                <p>THE PI GAME. Type out the digits of Pi.</p>
                <p>Score: {digits.current} {lost ? <Restart /> : ""}</p>
            </div>
            <div className={styles.game}>
                <span>{currentNum}</span><span className={lost ? styles.incorrect : styles.correct}>{lastNum.current}</span><span>{cursor}</span>
            </div>
        </div>
    );
}

export default PiGame;