import React, {useEffect, useState, useRef, useCallback} from "react";
import styles from "../Pong.module.css";

function Pong() {

    const speed = 4;

    // let gameOn = false;
    let dirX = useRef(1);
    let dirY = useRef(1);
    let paddleMouseX = useRef(100);
    let debug = useRef(false);
    let timer: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef();
    let lastPlatform = useRef(false);
    let score = useRef(0);
    let [gameOn, setGameOn] = useState(true);
    const documentRef = useRef(document);
    let [frameCoords, setFrameCoords] = useState({x: 1, y: 1, paddleWidth: 100, paddleX: 100});
    const brick = document.getElementById("brick");
    const paddle = document.getElementById("paddle");


    documentRef.current.addEventListener("keydown", (event) => {event.key === "\\" ? debug.current = !debug.current : void(0)});

    const Debug = () => {
        return (
            <p className={styles.debug}>
                <b>Debug</b> <br />
                PaddleWidth = {frameCoords.paddleWidth} <br />
                Direction = {"<" + dirX.current.toFixed(2) + ", " + dirY.current.toFixed(2) + ">"}
            </p>
        )
    }

    const doFrame = useCallback(() => {
        setGameOn(true);
        setFrameCoords(prevState => {return {x: prevState.x + dirX.current*speed, y: prevState.y + dirY.current*speed, paddleWidth: calculateWidth(score.current), paddleX: paddleMouseX.current}});
    }, []);

    const restart = () => {
        console.log(gameOn);
        if(!gameOn) {
            setGameOn(false);
            setFrameCoords({x: 1, y: 1, paddleWidth: 100, paddleX: 100});
            lastPlatform.current = false;
            score.current = 0;
            dirX.current = 1;
            dirY.current = 1;
            timer.current = setInterval(doFrame, 20);
        }
    }

    const calculateWidth = (score1: number) => {
        let width;
        let width_old = Math.floor(10000.0/(score1+90));
        if (width_old > 100) {width = 100} else if (width_old <= 10) {width = 10} else {width = width_old}
        return width;
    }

    useEffect(() => {
        setTimeout(() => {
            if(!timer.current) {
                timer.current = setInterval(doFrame, 20);
            }
        }, 1000);
    }, [doFrame]);

    useEffect(() => {
        if(brick) {
            if (frameCoords.x <= 0) {
                dirX.current = Math.abs(dirX.current);
            } else if(frameCoords.x >= 400 - brick!.offsetWidth) {
                dirX.current = -Math.abs(dirX.current);
            }
            if (frameCoords.y >= 200 - brick.offsetHeight) {
                setGameOn(false);
                clearInterval(timer.current);
            } else if(frameCoords.y <= 0) {
                dirY.current = -dirY.current;
                lastPlatform.current = false;
            }
            if(paddle) {
                if (
                    frameCoords.y + brick.offsetHeight >= paddle.offsetTop
                    && frameCoords.y <= paddle.offsetTop + paddle.offsetHeight
                    && frameCoords.x <= paddle.offsetLeft + paddle.offsetWidth
                    && frameCoords.x + brick.offsetWidth >= paddle.offsetLeft
                    && !lastPlatform.current
                ) {
                    dirY.current = -1;
                    dirX.current = Math.random()*4 - 2;
                    lastPlatform.current = true;
                    score.current ++;
                }
            }
        }
    }, [frameCoords, brick, paddle]);

    const movePaddle = (event: React.MouseEvent): void => {
        event.preventDefault();
        let mouseX: number = event.clientX;
        if (mouseX <= 0) {
            paddleMouseX.current = 0 - frameCoords.paddleWidth / 2;
        } else if (mouseX >= 400) {
            paddleMouseX.current = 400 - frameCoords.paddleWidth / 2;
        } else {
            paddleMouseX.current = mouseX - frameCoords.paddleWidth / 2;
        }
    }

    return (
        <div id="container">
            <div id={styles.animationContainer}>
                <img style={{'left': frameCoords.x, 'top': frameCoords.y}} alt="DVD" height="20" id="brick" className={styles.animate+" "+(debug.current ? styles.aNoDebug : styles.aDebug)} src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/DVD_logo.svg/1280px-DVD_logo.svg.png" />
                    <div className={styles.paddle} id="paddle" style={{'left': frameCoords.paddleX, 'width' : frameCoords.paddleWidth}}></div>
                    <p className={styles.animationText}>
                        Pong: Javascript Edition <br />
                        Press \ to {debug.current ? "hide" : "show"} debug info
                    </p>
                    <div id={styles.mouseTracker} onMouseMove={(e: React.MouseEvent) => movePaddle(e)}></div>
            </div>
            <p className={styles.score}>Score: {score.current}</p>
            {debug.current ? <Debug /> : ""}
            {gameOn ? "" : <div className={styles.lose}><span>You lost! </span><button onClick={restart}>Restart</button></div>}
        </div>
    );
}

export default Pong;