import React, {useState} from "react";
import styles from "../Captcha.module.css";

function Captcha() {
    let [showModal, setShowModal] = useState(false);
    let [puzzleId, setPuzzleId] = useState(0);

    // Modal for showing the puzzles!
    const Modal = () => {
        return (
            <div className={styles.modal}>
                <div className={styles.modalContent}>
                    <Puzzle />
                </div>
            </div>
        );
    }

    // All puzzles
    const Puzzle = () => {
        switch(puzzleId) {
            case 0:
                return (
                    <>
                        <p>Puzzle 1: Text Test</p>
                        <button onClick={() => {setPuzzleId(1)}}>Next puzzle</button>
                    </>
                )
            case 1:
                return (
                    <p>Puzzle 2: Image Test</p>
                )
            default:
                return (
                    <p>No puzzle</p>
                )
        }
    }

    return (
        <div id={styles.body}>
            <div id={styles.captchaContainer}>
                <input type="checkbox" name="captcha" onChange={(e) => {setShowModal(e.target.checked); console.log(showModal)}}/> <label htmlFor="captcha">I'm not a robot</label>
            </div>
            {showModal ? <Modal /> : ""}
        </div>
    )
}

export default Captcha;