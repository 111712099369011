import React, {useRef, useState, useEffect} from "react";
import styles from "../Clicker.module.css";

function Clicker() {
    let [pieceCoords, setPieceCoords] = useState({x: 100, y: 100});
    let initialTime = useRef(15);
    let [timeLeft, setTimeLeft] = useState(initialTime.current);
    let score = useRef(0);
    let timer: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef();

    useEffect(() => {
        if(!timer.current) {
            timer.current = setInterval(tickTimer, 1000);
        }
    }, []);

    const tickTimer = () => {
        setTimeLeft(prev => {if(prev-1 === 0){clearInterval(timer.current);} return prev-1;});
    }

    function clickHandler() {
        if(timeLeft > 0) {
            score.current += 1;
            setPieceCoords({x: Math.random() * 485, y: Math.random() * 285});
        }
    }

    function restart() {
        setTimeLeft(initialTime.current);
        setPieceCoords({x: Math.random() * 485, y: Math.random() * 285});
        score.current = 0;
        clearInterval(timer.current);
        timer.current = setInterval(tickTimer, 1000);
    }

    return (
        <div id={styles.container}>
            <h1>Click the button but it moves</h1>
            <div id={styles.game}>
                <div id={styles.piece} onClick={clickHandler} style={{'left': pieceCoords.x, 'top': pieceCoords.y}}></div>
            </div>
            <p>{timeLeft}s</p>
            <p>Score: {score.current} <br /> CpS: {(initialTime.current - timeLeft) === 0 ? 0 : score.current / (initialTime.current - timeLeft)}</p>
            <button onClick={restart}>Restart</button>
        </div>
    );
}

export default Clicker;