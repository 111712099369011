import React from 'react';
import '../App.css';
import Nav from "./Nav";
import Home from "./Home";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Pong from "./Pong";
import Captcha from "./Captcha";
import PiGame from "./PiGame";
import Clicker from "./Clicker";
import Scorer from './Scorer';

function App() {
  return (
    <>
        <BrowserRouter>
            <Nav />
            <div style={{"margin": "10px"}}>
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/pong" element={<Pong />} />
                  <Route path="/captcha" element={<Captcha />} />
                  <Route path="/pi" element={<PiGame />} />
                  <Route path="/clicker" element={<Clicker />} />
                  <Route path="/scorer" element={<Scorer />} />
              </Routes>
            </div>
        </BrowserRouter>
    </>
  );
}

export default App;
